import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import DailyTrendEventsButton from "./DailyTrendEventsButton.jsx";

export default angular.module("app.components.dailyTrendEventsButton", []).name;

// matches <br-daily-trend-events-button>
angularizeDirective(
  DailyTrendEventsButton,
  "brDailyTrendEventsButton",
  angular.module("app.components.dailyTrendEventsButton"),
  {
    item: "=",
  }
);
