import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import PvcBurden from "./PvcBurden.jsx";

export default angular.module("app.components.pvcBurden", []).name;

// matches <br-pvc-burden>
angularizeDirective(PvcBurden, "brPvcBurden", angular.module("app.components.pvcBurden"), {
  pvc: "=",
  toggles: "=",
  study: "=",
  type: "=",
});
