/* eslint-env browser */
import React from "react";
import {useForm} from "react-hook-form";
import {DateTime} from "luxon";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import LibraryBooks from "@mui/icons-material/LibraryBooks";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {useStudiesDispatch} from "../../contexts/StudiesContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import CompactStudyInfo from "../../shared/react/CompactStudyInfo.jsx";
import FormFileInput from "../../shared/react/FormFileInput.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import IconWithText from "../../shared/react/IconWithText.jsx";
import StartDateTimeInput from "../../shared/react/StartDateTimeInput.jsx";
import SwitchInput from "../../shared/react/SwitchInput.jsx";
import MdnCriteriaTooltip from "../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";

function ReportUpload({study}) {
  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  const [submitting, setSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = React.useCallback((event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  }, []);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useForm();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const dispatch = useStudiesDispatch();

  const onSubmit = React.useCallback(
    async (data) => {
      if (data.uploadedReport) {
        setSubmitting(true);

        const reportUploadData = new FormData();
        reportUploadData.append("file", data.uploadedReport);

        // append the generated report data onto the form data
        reportUploadData.append("facilityId", study.facilityId);
        reportUploadData.append("facilityName", study.facility.name);
        reportUploadData.append("tzSerial", study.currentEnrollment.tzSerial);
        reportUploadData.append("studyId", study.studyId || study.id);
        reportUploadData.append("timestamp", DateTime.now().toISO());
        reportUploadData.append("studyType", study.studyType);
        reportUploadData.append("meetsMdnCriteria", data.meetsMdnCriteria);

        // Update the recorded duration (if applicable), then
        //  create a new row in the generatedReports table, then
        //  use the resulting reportId to upload the report pdf
        try {
          if (study.recordedDuration !== Number(data.recordedDuration)) {
            await axios({
              method: "patch",
              url: `/studies/${study.studyId || study.id}`,
              data: {recordedDuration: Number(data.recordedDuration)},
            });
          }

          const {studyStartDate} = data;
          if (
            !DateTime.fromISO(study.studyStartDate).equals(DateTime.fromISO(studyStartDate)) &&
            ["holter", "extendedHolter"].includes(study.studyType) &&
            study.dataReceived
          ) {
            await axios({
              url: `/studies/${study.id}/start-date`,
              method: "post",
              data: {studyStartDate, note: "Update Study Start Date"},
            });
          }

          await axios({
            method: "post",
            data: reportUploadData,
            url: `/generatedReports/pdf`,
          });

          const {
            data: [updatedStudy],
          } = await axios({
            method: "get",
            url: "/studies",
            params: {id: study.studyId || study.id},
          });
          dispatch({type: "updated", updatedElement: updatedStudy});

          setSubmitting(false);
          handleClose();

          //---------------------------------------------------------------------------
          // Upload success dialog
          //---------------------------------------------------------------------------
          try {
            await confirm({
              title: "Report Successfully Uploaded",
              confirmationText: "View Reports Page",
              cancellationText: "Ok",
            });

            // The "View Reports Page" button opens the reports page in a new tab
            //  with the studyId auto-filled in the search bar
            window.localStorage.setItem(
              "search",
              JSON.stringify({
                text: `study:${study.studyId || study.id}`,
                timeExpired: DateTime.local().valueOf() + 30000,
              })
            );
            window.open(`/reports`, "_blank");
          } catch (err) {
            // If the "Ok" button is clicked, an error is thrown and the popup closes
            // So simply catch the error and do nothing
          }
        } catch (err) {
          setSubmitting(false);
          setError(err?.response?.data?.title || err.message);
        }
      }
    },
    [
      confirm,
      dispatch,
      handleClose,
      study.currentEnrollment.tzSerial,
      study.dataReceived,
      study.facility.name,
      study.facilityId,
      study.id,
      study.recordedDuration,
      study.studyId,
      study.studyStartDate,
      study.studyType,
    ]
  );

  return (
    <Box sx={{p: 2}} textAlign="center">
      <Button
        onClick={() => setOpen(true)}
        color="secondary"
        variant="contained"
        data-cy={`${study.id}-open-report-upload-popup`}
        sx={{borderRadius: 0.5}}
      >
        Upload Report
      </Button>
      <Dialog onClose={handleClose} open={open} data-cy="report-upload-form">
        <Alert message={error} setMessage={setError} level="error" />
        <DialogTitle>
          <IconWithText icon={<LibraryBooks color="secondary" />} text="Upload Report" />
        </DialogTitle>
        <Divider sx={{bgcolor: "secondary.main"}} />

        <DialogContent>
          {!["holter", "extendedHolter"].includes(study.studyType) &&
            study.studyState === "active" &&
            !study.studyEndDate && (
              <Alert
                message="The recording has not yet reached the prescribed duration. Are you sure you want to upload a report for this study?"
                level="warning"
                otherProps={{mb: 2}}
              />
            )}
          <Typography>Upload a report for the following study:</Typography>

          <Grid container mt={2} mb={4}>
            <Grid item xs={12} pb={3}>
              <CompactStudyInfo study={study} />
            </Grid>

            <Grid item xs={12} sm={6} pr={2}>
              <FormStringInput
                control={control}
                name="recordedDuration"
                label="Duration of Recording"
                defaultValue={study.recordedDuration || study.configuredDuration}
                type="number"
                size="small"
                otherProps={{
                  variant: "outlined",
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end" sx={{fontSize: 10}}>
                        hours
                      </InputAdornment>
                    ),
                    min: 1,
                    max: 720,
                  },
                }}
                rules={{
                  required: "Duration of recording is required",
                  min: {
                    value: 1,
                    message: "Study duration must be greater than or equal to 1 hour",
                  },
                  max: {
                    value: 720,
                    message: "Study duration must be less than or equal to 720 hours",
                  },
                }}
                data-cy="recorded-duration-input"
              />
            </Grid>

            {/* Study Start Date */}
            {["holter", "extendedHolter"].includes(study.studyType) && study.dataReceived && (
              <Grid item xs={12} sm={6} pl={2}>
                <StartDateTimeInput
                  control={control}
                  study={study}
                  label="Study Start Date"
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>

          <FormFileInput
            control={control}
            name="uploadedReport"
            label="Drag & Drop a Report PDF"
            id="uploadedReportInput"
            preview
            accept={{"application/pdf": [".pdf"]}}
            rules={{
              pattern: {
                value: /\.pdf$/i,
                message: "The report must be a PDF",
              },
              required: {
                value: true,
                message: "Select a report",
              },
            }}
          />
        </DialogContent>

        <DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
          <Box mb={2} ml={2}>
            <SwitchInput
              control={control}
              name="meetsMdnCriteria"
              label={
                <MdnCriteriaTooltip
                  criteria={study.studyDetails?.physicianNotes || study.physicianNotes}
                  toggle
                />
              }
              data-cy="mdn-criteria-toggle"
              color="error"
            />
          </Box>
          <Box mb={2} mr={2}>
            <Button color="secondary" onClick={handleClose} data-cy="cancel-upload-button" sx={{mr: 2}}>
              Cancel
            </Button>

            <LoadingButton
              data-cy="upload-report-button"
              variant="contained"
              color="secondary"
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
              loading={submitting}
            >
              Upload
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

ReportUpload.propTypes = {
  study: PropTypes.object.isRequired,
};

export default ReportUpload;
