import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import MdnCriteriaTooltip from "./MdnCriteriaTooltip.jsx";

export default angular.module("app.components.mdnCriteriaTooltip", []).name;

// matches <br-mdn-criteria-tooltip>
angularizeDirective(
  MdnCriteriaTooltip,
  "brMdnCriteriaTooltip",
  angular.module("app.components.mdnCriteriaTooltip"),
  {criteria: "=", met: "=", toggle: "="}
);
