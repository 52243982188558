import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import PatientDiaryTable from "./PatientDiaryTable.jsx";

export default angular.module("app.components.patientDiaryTable", []).name;

// matches <br-patient-diary-table>
angularizeDirective(
  PatientDiaryTable,
  "brPatientDiaryTable",
  angular.module("app.components.patientDiaryTable"),
  {
    study: "=",
  }
);
