/* eslint-env browser */
/* eslint-disable complexity */

import React from "react";
import {useForm} from "react-hook-form";
import {jsPDF as JSPDF} from "jspdf";
import {useConfirm} from "material-ui-confirm";
import {PDFDocument} from "pdf-lib";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Close from "@mui/icons-material/Close";
import CloudDone from "@mui/icons-material/CloudDone";
import CloudUpload from "@mui/icons-material/CloudUpload";
import Description from "@mui/icons-material/Description";
import DoDisturbOff from "@mui/icons-material/DoDisturbOff";
import DoDisturbOn from "@mui/icons-material/DoDisturbOn";
import Error from "@mui/icons-material/Error";
import MarkEmailRead from "@mui/icons-material/MarkEmailRead";
import MarkEmailUnread from "@mui/icons-material/MarkEmailUnread";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Print from "@mui/icons-material/Print";
import Task from "@mui/icons-material/Task";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {useReportsDispatch} from "../../contexts/ReportsContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";
import InfoTooltip from "../../shared/react/InfoTooltip.jsx";
import {createAndFormatLogo, fixSVGEncoding, getLogoFileExtension} from "../../shared/react/Logo.js";
import SwitchInput from "../../shared/react/SwitchInput.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import TextWithNewlines from "../../shared/react/TextWithNewlines.jsx";
import {formatDateAndTime} from "../DateAndTime/DateAndTime.jsx";
import useDoesNotMeetExtendedHolterRequirements from "../hooks/useDoesNotMeetExtendedHolterRequirements.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import useJwt from "../hooks/useJwt.jsx";
import useStudyTypeNames from "../hooks/useStudyTypeNames.jsx";
import MdnCriteriaTooltip from "../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";
import LogoNotFoundMessage from "./LogoNotFoundMessage.jsx";
import RejectConfirmation from "./RejectConfirmation.jsx";

//---------------------------------------------------------------------------
// Define Constants for Cover Page Generation
//---------------------------------------------------------------------------
const page = {
  height: 11,
  width: 8.5,
  margin: 0.333,
  tab1: 2.2, // Signature Labels
  padding: 0.25,
  dpi: 96,
};
page.rightMargin = page.width - page.margin;
page.headerHeight = 1 - page.margin;
page.tab2 = page.tab1 + page.padding; // Signature Values

const color = {
  primary: "#0277bd",
  primaryDark: "#004c8b",
  accentGreyLight: "#62727b",
  separatorDark: "#dddddd",
  text: "#212121",
};

//---------------------------------------------------------------------------
// Helpers
//---------------------------------------------------------------------------
async function getLogoFile(filename) {
  const {data} = await axios({
    method: "get",
    url: `/facilities/logo/${filename}`,
    headers: {"Content-Type": "application/json"},
    responseType: "arraybuffer",
  });

  const typedArray = new Uint8Array(data);
  const stringChar = String.fromCharCode.apply(null, typedArray);
  const base64String = btoa(stringChar);

  return fixSVGEncoding(base64String, getLogoFileExtension(filename));
}
async function getFacilityLogoWithFallback(filename) {
  let logoFile;
  let logoFallback = false;

  try {
    logoFile = await getLogoFile(filename);
  } catch (err) {
    logoFallback = true;
    filename = "bitrhythmLogo.svg";

    logoFile = await getLogoFile(filename);
  }

  const logoFileExtension = getLogoFileExtension(filename);

  return {logoFile, logoFileExtension, logoFallback};
}

function PdfReportDialog({open, setOpen, report}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features, softwareVersion} = useEnvironmentVariables();
  const displayableStudyTypes = useStudyTypeNames("complex");

  const {isInAnyRole, fullName} = useJwt();

  //---------------------------------------------------------------------------
  // Dialog Title
  //---------------------------------------------------------------------------
  const title = `${report.reportType} Report ${report.studyId}-${report.reportNumber}`;
  const auditText = React.useMemo(() => {
    let action = "Generated";
    if (report.reportType === "Uploaded") {
      action = "Uploaded";
    } else if (report.lastModifiedByUser?.fullName) {
      action = "Edited";
    }

    const user = report.lastModifiedByUser?.fullName || report.generatedByUser?.fullName;

    return user ? `${action} by ${user}` : "";
  }, [report.reportType, report.lastModifiedByUser, report.generatedByUser]);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control, watch} = useForm();
  const watchPhysicianComments = watch("physicianComment");

  //---------------------------------------------------------------------------
  // Set up hooks for confirmation dialogs and styling
  //---------------------------------------------------------------------------
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useReportsDispatch();

  //---------------------------------------------------------------------------
  // Error and Success alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  //---------------------------------------------------------------------------
  // Fetch PDF, study, and facility logo from the API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [pdfReport, setPdfReport] = React.useState(report);
  const [submitting, setSubmitting] = React.useState(false);
  const [eSignEnabled, setESignEnabled] = React.useState(false);
  const [originalFileUrl, setOriginalFileUrl] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);
  const [study, setStudy] = React.useState({});
  const [logo, setLogo] = React.useState(null);

  const generateCoverPage = React.useCallback(
    async (reportStudy, reportLogo, pageCount, unixTimestamp, physicianComment) => {
      const reportTitle = `${
        displayableStudyTypes[pdfReport.studyType]?.short || displayableStudyTypes[pdfReport.studyType]
      } Report`;
      const patientName =
        pdfReport.studyDetails?.patientName === pdfReport.tzSerial ? "" : pdfReport.studyDetails?.patientName;
      const studyStartDate = formatDateAndTime({
        datetime: reportStudy.studyStartDate,
        zone: reportStudy.timeZone,
      });
      const recordingDuration = Math.ceil(reportStudy.recordedDuration / 24);
      const displayedRecordingDuration = `${recordingDuration} day${recordingDuration === 1 ? "" : "s"}`;
      const displayedStudyType =
        displayableStudyTypes[pdfReport.studyType]?.long || displayableStudyTypes[pdfReport.studyType];
      let displayedSignature = "";
      let displayedTimestamp = "";
      let displayedName = "";
      if (unixTimestamp) {
        displayedSignature = `Electronically signed by ${fullName}`;
        displayedTimestamp = formatDateAndTime({datetime: unixTimestamp, zone: reportStudy.timeZone});
      }
      if (isInAnyRole(["physician"])) {
        displayedName = fullName;
      }

      const doc = new JSPDF({
        unit: "in",
        format: "letter",
        orientation: "portrait",
      });

      /**
       * Returns line height
       * @param {Number} fontPoint
       * @param {Number} [heightFactor] Line Spacing; jsPDF uses 1.15 by default
       * @returns {Number} line height in inches
       */
      const calculateLineHeight = (fontPoint, heightFactor = doc.getLineHeightFactor()) => {
        const pxPerPt = 4 / 3;
        return (fontPoint * pxPerPt * heightFactor) / page.dpi;
      };

      //---------------------------------------------------------------------------
      // Header
      //---------------------------------------------------------------------------
      if (reportLogo) {
        doc.addImage(
          reportLogo.src,
          reportLogo.format,
          reportLogo.x,
          reportLogo.y,
          reportLogo.w,
          reportLogo.h
        );
      }

      const halfPageWidth = page.width / 2;
      const rightBound = page.rightMargin - page.padding;
      const leftBound = page.margin + page.padding;
      const lowerBound = page.height - page.margin;
      const footerY = lowerBound - page.padding;
      const headerY = page.headerHeight + page.margin;
      doc
        // Title (bold weight)
        .setFont("Helvetica", "bold")
        .setFontSize(14)
        .setTextColor(color.primaryDark)
        .text(reportTitle, halfPageWidth, 0.65, {align: "center"})
        // Subtitle (bold weight)
        .setFontSize(9)
        .setTextColor(color.accentGreyLight)
        .text(`Study ID: ${pdfReport.studyId}`, halfPageWidth, 0.9, {align: "center"})
        // Header Info (bold weight)
        .text(`Patient: ${patientName}`, page.margin, 0.6)
        .text(`Patient ID: ${pdfReport.studyDetails?.patientId}`, page.margin, 0.75)
        .text(`Device: ${pdfReport.tzSerial}`, page.margin, 0.9);

      //---------------------------------------------------------------------------
      // Body
      //---------------------------------------------------------------------------

      // Elements of infoTable.columns are columns (a third column could be added and it will resize)
      //   Elements in each of those elements are rows
      //     Elements in each of those elements are strings.
      //     A solo string is treated as a title, and two strings will be displayed as a key/value pair
      const infoTable = {
        topY: headerY + 0.3, // Starts below the header
        leftX: page.margin,
        rightX: page.rightMargin,
        keyRatio: 0.2, // 20% of each column will be the key
        columns: [
          [
            ["Patient Summary:"],
            ["Name:", pdfReport.studyDetails?.patientName],
            ["Patient ID:", pdfReport.studyDetails?.patientId],
            ["Language:", pdfReport.studyDetails?.patientLanguage],
            ["DOB:", pdfReport.studyDetails?.patientDob || ""],
            ["Gender:", pdfReport.studyDetails?.patientGender],
            ["Height:", pdfReport.studyDetails?.patientHeight],
            ["Weight:", pdfReport.studyDetails?.patientWeight],
            ["Phone:", pdfReport.studyDetails?.patientPhoneNumber],
            ["Address:", pdfReport.studyDetails?.patientAddress],
          ],
          [
            ["Study Summary:"],
            ["Device:", pdfReport.tzSerial],
            ["Study ID:", pdfReport.studyId],
            ["Start Date:", studyStartDate],
            ["Duration:", displayedRecordingDuration],
            ["Study Type:", displayedStudyType],
            ["Facility:", pdfReport.facilityName],
            ["Indication:", reportStudy.studyIndication],
          ],
        ],
      };

      doc.setTextColor(color.text).setFontSize(9);
      let maxColumnHeight = 0;

      // Procedurally add infoTable to doc (nested loop)
      infoTable.columns.forEach((column, c) => {
        const titleRowHeight = 0.25;
        const rowHeight = 0.2;
        const columnWidth = (infoTable.rightX - infoTable.leftX) / infoTable.columns.length;
        const leftX = infoTable.leftX + columnWidth * c;
        const rightX = leftX + columnWidth;

        const keyTab = leftX + columnWidth * infoTable.keyRatio;
        const valueTab = keyTab + 0.1;
        const valueWidth = rightX - valueTab - 0.1;

        let rowHeightCounter = infoTable.topY;
        column.forEach((row) => {
          if (row.length === 0) {
            // Display row as blank
            rowHeightCounter += rowHeight;
          } else if (row.length === 1) {
            // Display row as a title (centered, bold)
            doc
              .setFont("Helvetica", "bold")
              .text(`${row[0]}`, (leftX + rightX) / 2, rowHeightCounter, {align: "center"});
            rowHeightCounter += titleRowHeight;
          } else {
            // Display row as key (bold) and value
            doc
              .setFont("Helvetica", "bold")
              .text(`${row[0]}`, keyTab, rowHeightCounter, {align: "right"})
              .setFont("Helvetica", "")
              .text(`${row[1]}`, valueTab, rowHeightCounter, {maxWidth: valueWidth});

            // Use jsPDF plugin to calculate how many lines were used
            const additionalRows = doc.splitTextToSize(`${row[1]}`, valueWidth).length - 1;
            rowHeightCounter += rowHeight + additionalRows * calculateLineHeight(9);
          }
        });

        maxColumnHeight = Math.max(maxColumnHeight, rowHeightCounter - rowHeight);
      });

      // Start the next elements on the page at a height based on maxColumnHeight
      const physicianFieldY = maxColumnHeight + 0.1;

      // Draw the Left Vertical Grey Line by the info table
      doc
        .setDrawColor(color.separatorDark)
        .setLineWidth(3 / page.dpi)
        .line(infoTable.leftX, headerY, infoTable.leftX, physicianFieldY);

      // Draw vertical grey lines on the right of each column of the info table
      // This loop needs to be duplicated because the height of all of these lines is calculated by maxColumnHeight
      infoTable.columns.forEach((column, c) => {
        const columnWidth = (infoTable.rightX - infoTable.leftX) / infoTable.columns.length;
        const rightX = infoTable.leftX + columnWidth * (c + 1);

        doc.line(rightX, headerY, rightX, physicianFieldY);
      });

      // Physician Signature and Comments
      doc
        .setTextColor(color.text)
        .text("Reading Physician Name:", page.tab1, physicianFieldY + 0.4, {align: "right"})
        .text("Reading Physician Signature:", page.tab1, physicianFieldY + 0.7, {align: "right"})
        .text("Date:", page.tab1, physicianFieldY + 1, {align: "right"})
        .text("Reading Physician Comments:", leftBound, physicianFieldY + 1.7)
        .setFont("Helvetica", "")
        .text(displayedName, page.tab2, physicianFieldY + 0.4)
        .text(displayedSignature, page.tab2, physicianFieldY + 0.7)
        .text(displayedTimestamp, page.tab2, physicianFieldY + 1)
        // Dynamic Physician Fields
        .text(physicianComment, leftBound, physicianFieldY + 2, {maxWidth: page.width - 2 * leftBound});

      // Lines
      doc
        // Grey Lines around the physician fields
        .setDrawColor(color.separatorDark)
        .setLineWidth(3 / page.dpi)
        .line(page.margin, physicianFieldY, page.rightMargin, physicianFieldY)
        .line(page.margin, physicianFieldY + 1.35, page.rightMargin, physicianFieldY + 1.35)
        .line(page.margin, physicianFieldY, page.margin, footerY)
        .line(page.rightMargin, physicianFieldY, page.rightMargin, footerY)
        // Signature Lines
        .setDrawColor(color.text)
        .setLineWidth(1 / page.dpi)
        .setLineCap("square")
        .line(page.tab2 - 0.1, physicianFieldY + 0.45, rightBound, physicianFieldY + 0.45)
        .line(page.tab2 - 0.1, physicianFieldY + 0.75, rightBound, physicianFieldY + 0.75)
        .line(page.tab2 - 0.1, physicianFieldY + 1.05, rightBound, physicianFieldY + 1.05)
        // Header & Footer Separators
        .setDrawColor(color.primary)
        .setLineWidth(3 / page.dpi)
        .line(page.margin, headerY, page.rightMargin, headerY)
        .line(page.margin, footerY, page.rightMargin, footerY);

      //---------------------------------------------------------------------------
      // Footer
      //---------------------------------------------------------------------------
      const reportNumberInfo = `Report Number: ${pdfReport.reportNumber}`;
      const renderedInfo = `Rendered in: ${softwareVersion}`;
      const pageInfo = `Cover Page (${pageCount} page report)`;
      doc
        .setTextColor(color.accentGreyLight)
        .text(reportNumberInfo, page.margin, lowerBound - 0.1)
        .text(renderedInfo, halfPageWidth, lowerBound - 0.1, {align: "center"})
        .text(pageInfo, page.rightMargin, lowerBound - 0.1, {align: "right"});

      // Finalize
      return doc.output("arraybuffer");
    },
    [pdfReport, softwareVersion, fullName, isInAnyRole, displayableStudyTypes]
  );
  const addCoverPage = React.useCallback(
    async (reportStudy, reportLogo, fileURL, unixTimestamp = null, physicianComment = "") => {
      //---------------------------------------------------------------------------
      // Combine the cover page with the report using pdf-lib
      //---------------------------------------------------------------------------
      const fullDoc = await PDFDocument.create();

      // Get the report from the browser's file system
      const reportBuffer = await (await fetch(fileURL)).arrayBuffer();
      const reportDoc = await PDFDocument.load(reportBuffer);
      const reportPages = await fullDoc.copyPages(reportDoc, reportDoc.getPageIndices());

      // Create an in-memory cover page using jsPDF
      const coverBuffer = await generateCoverPage(
        reportStudy,
        reportLogo,
        reportPages.length,
        unixTimestamp,
        physicianComment
      );
      const coverDoc = await PDFDocument.load(coverBuffer);
      const coverPage = await fullDoc.copyPages(coverDoc, coverDoc.getPageIndices());

      // Add Pages
      coverPage.forEach((p) => fullDoc.addPage(p));
      reportPages.forEach((p) => fullDoc.addPage(p));

      // Finalize Doc
      const outputBytes = await fullDoc.save();
      return new File([outputBytes], "file.pdf", {type: "application/pdf"});
    },
    [generateCoverPage]
  );
  const testPdfOrThrow = React.useCallback(async (file) => {
    // Use pdf-lib to validate a given file pdf

    const targetDoc = await PDFDocument.create();

    const testBuffer = await (await fetch(file)).arrayBuffer();
    const testDoc = await PDFDocument.load(testBuffer);
    const testPages = await targetDoc.copyPages(testDoc, testDoc.getPageIndices());

    testPages.forEach((p) => targetDoc.addPage(p));
  }, []);

  const getData = React.useCallback(async () => {
    setLoading(true);

    try {
      const {
        data: {logoFilename},
      } = await axios({
        method: "get",
        url: `/facilities/logo/filename/${pdfReport.facilityId}`,
      });

      let pdfUrl = pdfReport.publishedUrl || `generatedReports/pdf/${pdfReport.studyId}/${pdfReport.id}.pdf`;
      if (pdfUrl[0] !== "/") {
        // Ensure url is prepended with "/"
        pdfUrl = `/${pdfUrl}`;
      }

      const [
        {data: workflowSettings},
        {data: pdf},
        {data: returnedStudy},
        {logoFile, logoFileExtension, logoFallback},
      ] = await Promise.all([
        axios({
          method: "get",
          url: `/workflowSettings/${pdfReport.facilityId}`,
        }),
        axios({
          method: "get",
          url: pdfUrl,
          responseType: "arraybuffer",
        }),
        axios({
          method: "get",
          url: `/studies/${pdfReport.studyId}`,
        }),
        getFacilityLogoWithFallback(logoFilename),
      ]);

      //---------------------------------------------------------------------------
      // Workflow Settings
      //---------------------------------------------------------------------------
      setESignEnabled(workflowSettings?.eSignEnabled);

      //---------------------------------------------------------------------------
      // Study
      //---------------------------------------------------------------------------
      setStudy(returnedStudy);

      //---------------------------------------------------------------------------
      // Facility Logo
      //---------------------------------------------------------------------------
      if (logoFallback) {
        try {
          await confirm({
            title: "Logo Not Found",
            content: <LogoNotFoundMessage facilityName={pdfReport.facilityName} />,
            confirmationText: "Proceed",
          });
        } catch (err) {
          // If "Cancel" was clicked, close the report viewer
          setOpen(false);
        }
      }

      const formattedLogo = await createAndFormatLogo(page, logoFile, logoFileExtension);
      setLogo(formattedLogo);

      //---------------------------------------------------------------------------
      // PDF with Cover Page
      //---------------------------------------------------------------------------
      const file = new File([pdf], "file.pdf", {type: "application/pdf"});
      const originalFile = URL.createObjectURL(file);
      setOriginalFileUrl(originalFile);

      await testPdfOrThrow(originalFile);

      if (pdfReport.state === "submitted") {
        const reportWithPossibleCoverPage = await addCoverPage(returnedStudy, formattedLogo, originalFile);
        setFileUrl(URL.createObjectURL(reportWithPossibleCoverPage));
      } else {
        setFileUrl(originalFile);
      }
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [
    pdfReport.facilityId,
    pdfReport.publishedUrl,
    pdfReport.studyId,
    pdfReport.id,
    pdfReport.state,
    pdfReport.facilityName,
    testPdfOrThrow,
    confirm,
    setOpen,
    addCoverPage,
  ]);

  React.useEffect(() => {
    if (open) {
      getData();
    }
  }, [getData, open]);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      setOpen(false);
    },
    [setOpen]
  );

  //---------------------------------------------------------------------------
  // Constants for determining what components are displayed
  //---------------------------------------------------------------------------
  const watchRecordedDuration =
    watch("recordedDuration") || study.recordedDuration || study.configuredDuration;

  const displayRejectButton = React.useMemo(() => {
    if (isInAnyRole(["physician"])) {
      return pdfReport.state === "submitted";
    }

    return ["generated", "submitted", "published"].includes(pdfReport.state);
  }, [pdfReport.state, isInAnyRole]);

  const displayUnrejectButton = React.useMemo(() => {
    if (isInAnyRole(["physician"])) {
      return pdfReport.state === "rejectedByPhysician";
    }
    return pdfReport.state === "rejectedByTech" || pdfReport.state === "rejectedByPhysician";
  }, [pdfReport.state, isInAnyRole]);

  const displayDurationInput = React.useMemo(() => {
    return !isInAnyRole(["physician"]) && pdfReport.state === "generated";
  }, [isInAnyRole, pdfReport.state]);

  const displayCommentsInput = React.useMemo(() => {
    return (
      isInAnyRole(["physician"]) &&
      eSignEnabled &&
      pdfReport.reportType === "Uploaded" &&
      pdfReport.state === "submitted"
    );
  }, [eSignEnabled, pdfReport.reportType, pdfReport.state, isInAnyRole]);

  const doesNotMeetExtendedHolterRequirements = useDoesNotMeetExtendedHolterRequirements(
    study?.studyType,
    study?.downgradeAuthorized,
    study?.configuredDuration,
    watchRecordedDuration
  );

  //---------------------------------------------------------------------------
  // Report state management
  //---------------------------------------------------------------------------
  const reportStates = React.useMemo(
    () => ({
      generated: {text: "Pending QC", icon: <Description />},
      submitted: {
        text: "Pending Signature",
        icon: isInAnyRole(["physician"]) ? <MarkEmailUnread /> : <MarkEmailRead />,
      },
      published: {text: "Published", icon: <CloudDone />},
      signed: {text: "Signed", icon: <Task />},
      printed: {text: "Printed", icon: <Print />},
      rejectedByTech: {text: "Rejected by Tech", icon: <DoDisturbOn />},
      rejectedByPhysician: {text: "Rejected by Clinic", icon: <DoDisturbOn />},
    }),
    [isInAnyRole]
  );
  const action = React.useMemo(() => {
    // Pending QC Uploaded reports can be submitted or published
    if (pdfReport.reportType === "Uploaded" && pdfReport.state === "generated") {
      return eSignEnabled
        ? {text: "Submit for Signature", icon: <MarkEmailUnread />}
        : {text: "Publish", icon: <CloudUpload />};
    }

    // Physicians can e-sign Pending Signature Uploaded reports
    if (displayCommentsInput) {
      return {text: "Agree and Sign"};
    }

    // Published or Signed reports can be printed
    if (pdfReport.state === "published" || pdfReport.state === "signed") {
      return {text: "Mark As Printed", icon: <Print />};
    }

    return null;
  }, [eSignEnabled, pdfReport.reportType, pdfReport.state, displayCommentsInput]);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (data) => {
      let propertiesToUpdate;

      setSubmitting(true);

      //---------------------------------------------------------------------------
      // Determine which properties are updating based on the report state
      //---------------------------------------------------------------------------
      try {
        switch (action?.text) {
          case "Submit for Signature":
            propertiesToUpdate = {
              state: "submitted",
              displayState: "submitted for signature",
              meetsMdnCriteria: data.meetsMdnCriteria,
              technicianSignedBy: fullName,
              technicianSignedAt: new Date().getTime(),
            };
            break;
          case "Publish":
            propertiesToUpdate = {
              state: "published",
              meetsMdnCriteria: data.meetsMdnCriteria,
              technicianSignedBy: fullName,
              technicianSignedAt: null,
            };
            break;
          case "Agree and Sign":
            propertiesToUpdate = {
              state: "signed",
              physicianComment: data.physicianComment,
              signedAt: new Date().getTime(),
            };
            break;
          case "Mark As Printed":
            propertiesToUpdate = {
              state: "printed",
              displayState: "marked as printed",
            };
            break;
          default:
            throw new Error("Cannot perform action");
        }

        //---------------------------------------------------------------------------
        // If e-Signing, we need to add the physician signature and comments to the
        // cover page, then update the report state
        //---------------------------------------------------------------------------
        if (action?.text === "Agree and Sign") {
          const currentTime = new Date().getTime();
          const signedReportFile = await addCoverPage(
            study,
            logo,
            originalFileUrl,
            currentTime,
            data.physicianComment
          );
          const signedReportBuffer = await signedReportFile.arrayBuffer();

          const formData = new FormData();
          const file = new Blob([signedReportBuffer]);
          formData.append("file", file);
          Object.entries(propertiesToUpdate).forEach(([key, value]) => {
            formData.append(key, value);
          });

          await axios({
            method: "post",
            url: `/generatedReports/sign/${pdfReport.id}`,
            data: formData,
          });
          setPdfReport((currentReport) => ({
            ...currentReport,
            state: "signed",
            signedAt: currentTime,
            physicianComment: data.physicianComment,
          }));
          dispatch({
            type: "updated",
            updatedElement: {
              ...pdfReport,
              state: "signed",
              signedAt: currentTime,
              physicianComment: data.physicianComment,
            },
          });

          let pdfUrl =
            pdfReport.publishedUrl || `generatedReports/pdf/${pdfReport.studyId}/${pdfReport.id}.pdf`;
          if (pdfUrl[0] !== "/") {
            // Ensure url is prepended with "/"
            pdfUrl = `/${pdfUrl}`;
          }

          const {data: signedPdf} = await axios({
            method: "get",
            url: pdfUrl,
            responseType: "arraybuffer",
          });

          const pdfFile = new File([signedPdf], "file.pdf", {type: "application/pdf"});
          const newFile = URL.createObjectURL(pdfFile);
          await testPdfOrThrow(newFile);

          setOriginalFileUrl(newFile);
          setFileUrl(newFile);
        }
        //---------------------------------------------------------------------------
        // Otherwise, just update the report state
        //---------------------------------------------------------------------------
        else {
          const {data: updatedReport} = await axios({
            method: "patch",
            url: `/generatedReports/${pdfReport.id}`,
            data: propertiesToUpdate,
          });
          setPdfReport((prevReport) => ({...prevReport, ...updatedReport}));
          dispatch({type: "updated", updatedElement: {...pdfReport, ...updatedReport}});

          // If we just submitted the report, add the temporary cover page
          if (updatedReport.state === "submitted") {
            const reportWithPossibleCoverPage = await addCoverPage(
              {...study, recordedDuration: Number(data.recordedDuration)},
              logo,
              originalFileUrl
            );
            setFileUrl(URL.createObjectURL(reportWithPossibleCoverPage));
          }
        }

        //---------------------------------------------------------------------------
        // Update the study if applicable
        //---------------------------------------------------------------------------
        const recordedDurationUpdated =
          data.recordedDuration &&
          (Number(data.recordedDuration) !== study.configuredDuration ||
            (study.recordedDuration && Number(data.recordedDuration) !== study.recordedDuration));
        const markStudyAsFinalized = features.finalizeStudy && data.markAsFinalized;

        const studyRequests = [];
        if (recordedDurationUpdated) {
          studyRequests.push(
            axios({
              method: "patch",
              url: `/studies/${study.id}`,
              data: {
                recordedDuration: Number(data.recordedDuration),
              },
            })
          );
        }
        if (markStudyAsFinalized) {
          studyRequests.push(
            axios({
              method: "post",
              url: `/studies/${pdfReport.studyId}/finalize`,
            })
          );
        }

        await Promise.all(studyRequests);

        setSuccess(`Report was successfully ${propertiesToUpdate.displayState || propertiesToUpdate.state}`);
      } catch (err) {
        setError(err.message);
      }

      setSubmitting(false);
    },
    [
      action?.text,
      study,
      features.finalizeStudy,
      fullName,
      addCoverPage,
      logo,
      originalFileUrl,
      pdfReport,
      dispatch,
      testPdfOrThrow,
    ]
  );

  const [openRejectConfirmation, setOpenRejectConfirmation] = React.useState(false);
  const rejectReport = React.useCallback(
    async (data) => {
      setSubmitting(true);

      try {
        const state = isInAnyRole(["clinicalStaff", "physician"]) ? "rejectedByPhysician" : "rejectedByTech";
        const propertiesToUpdate = {
          state,
          reasonForRejection: data.reasonForRejection,
        };

        const {data: updatedReport} = await axios({
          method: "patch",
          url: `/generatedReports/${pdfReport.id}`,
          data: propertiesToUpdate,
        });
        // Update the local displayed report
        setPdfReport((prevReport) => ({...prevReport, ...updatedReport, rejectedByUser: {fullName}}));
        dispatch({
          type: "updated",
          updatedElement: {...pdfReport, ...updatedReport, rejectedByUser: {fullName}},
        });

        // If tech is rejecting, make sure there is no cover page
        if (state === "rejectedByTech") {
          setFileUrl(originalFileUrl);
        }

        setOpenRejectConfirmation(false);
        setSuccess("Report successfully rejected");

        if (pdfReport.state === "published") {
          handleClose();
        }
      } catch (err) {
        setOpenRejectConfirmation(false);
        setError(err.message);
      }

      setSubmitting(false);
    },
    [isInAnyRole, pdfReport, dispatch, fullName, originalFileUrl, handleClose]
  );

  const handleClickUnreject = React.useCallback(async () => {
    setSubmitting(true);

    // Update the report state and fetch the study in case the study duration was changed
    try {
      let state = "generated";

      if (isInAnyRole(["clinicalStaff"])) {
        state = eSignEnabled ? "submitted" : "published";
      } else if (isInAnyRole(["physician"])) {
        state = "submitted";
      }

      const [{data: updatedReport}, {data: updatedStudy}] = await Promise.all([
        axios({
          method: "patch",
          url: `/generatedReports/${pdfReport.id}`,
          data: {state},
        }),
        axios({
          method: "get",
          url: `/studies/${pdfReport.studyId}`,
        }),
      ]);
      setPdfReport((prevReport) => ({...prevReport, ...updatedReport}));
      setStudy(updatedStudy);

      dispatch({type: "updated", updatedElement: {...pdfReport, ...updatedReport}});

      setSuccess("Report successfully un-rejected");
    } catch (err) {
      setError(err.message);
    }

    setSubmitting(false);
  }, [isInAnyRole, pdfReport, dispatch, eSignEnabled]);

  const handleClickConfirmRejection = React.useCallback(async () => {
    setSubmitting(true);

    try {
      const {data: updatedReport} = await axios({
        method: "patch",
        url: `/generatedReports/${pdfReport.id}`,
        data: {state: "rejectedByTech"},
      });

      // Update the local displayed report
      setPdfReport((prevReport) => ({...prevReport, ...updatedReport, rejectedByUser: {fullName}}));
      dispatch({
        type: "updated",
        updatedElement: {...pdfReport, ...updatedReport, rejectedByUser: {fullName}},
      });
    } catch (err) {
      setError(err.message);
    }

    setSubmitting(false);
  }, [pdfReport, dispatch, fullName]);

  //---------------------------------------------------------------------------
  // Handle updating the cover page preview if physician comments or
  // recording duration are edited
  //---------------------------------------------------------------------------
  const [displayedPhysicianComments, setDisplayedPhysicianComments] = React.useState("");
  const handleUpdateCoverPage = React.useCallback(async () => {
    const displayedRecordingDuration = study.recordedDuration;
    const validRecordingDuration = Number(watchRecordedDuration) > 0 && Number(watchRecordedDuration) < 721;

    if (
      isInAnyRole(["physician"]) &&
      (displayedPhysicianComments !== watchPhysicianComments ||
        (displayedRecordingDuration !== watchRecordedDuration && validRecordingDuration))
    ) {
      const reportWithPossibleCoverPage = await addCoverPage(
        {...study, recordedDuration: Number(watchRecordedDuration)},
        logo,
        originalFileUrl,
        null,
        watchPhysicianComments
      );
      setFileUrl(URL.createObjectURL(reportWithPossibleCoverPage));

      setDisplayedPhysicianComments(watchPhysicianComments);
    }
  }, [
    isInAnyRole,
    addCoverPage,
    study,
    logo,
    originalFileUrl,
    watchPhysicianComments,
    displayedPhysicianComments,
    watchRecordedDuration,
  ]);

  return (
    <>
      <Alert message={success} setMessage={setSuccess} level="info" variant="snackbar" />

      <Dialog fullWidth maxWidth="xl" open={open} data-cy="pdf-viewer-dialog">
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            zIndex: 1,
          }}
        >
          <Box display="inline-flex" alignItems="center" data-cy="dialog-title">
            <Tooltip title={reportStates[pdfReport.state]?.text}>
              {React.cloneElement(reportStates[pdfReport.state]?.icon, {
                color: pdfReport.meetsMdnCriteria ? "error" : "secondary",
              })}
            </Tooltip>
            &nbsp;&nbsp;{title}
            <Typography variant="caption" data-cy="audit-message">
              &nbsp;&nbsp;{auditText}
            </Typography>
          </Box>
          <Box>
            <IconButtonWithTooltip
              title="Open in New Tab"
              otherProps={{
                "data-cy": "open-in-new-tab-button",
                size: "small",
                href: fileUrl,
                target: "_blank",
                sx: {mr: 2},
              }}
            >
              <OpenInNew />
            </IconButtonWithTooltip>
            <IconButtonWithTooltip
              title="Close"
              onClick={handleClose}
              otherProps={{"data-cy": "close-button", size: "small", disabled: submitting}}
            >
              <Close />
            </IconButtonWithTooltip>
          </Box>
        </DialogTitle>

        <Alert message={error} setMessage={setError} level="error" otherProps={{sx: {mb: 0}}} />

        <DialogContent sx={{p: 0, minHeight: "80vh"}}>
          {(loading || submitting) && <TableLoading />}

          {!loading && !submitting && (
            <Grid container alignItems="stretch" columns={5}>
              {/* PDF VIEWER */}
              <Grid
                item
                xs={4}
                p={1}
                sx={{backgroundColor: theme.palette.background.main, height: "80vh"}}
                data-cy="pdf-viewer"
              >
                <embed src={fileUrl} width="100%" height="100%" />
              </Grid>

              {/* SIDEBAR */}
              <Grid
                item
                xs={1}
                p={2}
                sx={{borderLeft: 2, borderLeftColor: theme.palette.background.dark}}
                display="inline-flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {/* REPORT MODIFIERS */}
                <Box>
                  {/* REASON FOR REJECTION */}
                  {pdfReport.state.startsWith("rejected") && !!pdfReport.reasonForRejection && (
                    <Box
                      mb={2}
                      p={2}
                      sx={{backgroundColor: theme.palette.background.main, borderRadius: "4px"}}
                    >
                      <Typography variant="subtitle2" mb={1} data-cy="rejected-by-message">
                        {pdfReport.rejectedByUser?.fullName
                          ? `Rejected by ${pdfReport.rejectedByUser.fullName}`
                          : "Reason for Rejection"}
                      </Typography>
                      <TextWithNewlines
                        text={pdfReport.reasonForRejection}
                        fontSize="14px"
                        data-cy="reason-for-rejection"
                      />

                      {isInAnyRole(["clinicalStaff", "physician"]) &&
                        pdfReport.state === "rejectedByPhysician" && (
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleClickConfirmRejection}
                            sx={{mt: 2}}
                            data-cy="confirm-rejection-button"
                          >
                            Confirm
                          </Button>
                        )}
                    </Box>
                  )}

                  {/* MDN CRITERIA */}
                  <Box sx={{display: "inline-flex", justifyContent: "space-between"}} width="100%">
                    <MdnCriteriaTooltip
                      toggle={pdfReport.state === "generated"}
                      criteria={study?.physicianNotes}
                      met={!!pdfReport.meetsMdnCriteria}
                    />
                    {pdfReport.state === "generated" && (
                      <SwitchInput
                        control={control}
                        name="meetsMdnCriteria"
                        data-cy="mdn-criteria-toggle"
                        defaultValue={!!pdfReport.meetsMdnCriteria}
                        color="error"
                        otherProps={{edge: "end"}}
                      />
                    )}
                  </Box>

                  {/* ACTUAL DURATION */}
                  {displayDurationInput && (
                    <Box sx={{mt: 1, display: "inline-flex", justifyContent: "space-between"}} width="100%">
                      <Typography fontSize={14} mt={1}>
                        Duration of Recording
                      </Typography>
                      <FormStringInput
                        control={control}
                        name="recordedDuration"
                        defaultValue={study.recordedDuration || study.configuredDuration}
                        type="number"
                        size="small"
                        otherProps={{
                          fullWidth: false,
                          variant: "outlined",
                          sx: {width: 115},
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography fontSize={14}>hours</Typography>
                              </InputAdornment>
                            ),
                            min: 1,
                            max: 720,
                          },
                          onBlur: handleUpdateCoverPage,
                        }}
                        rules={{
                          required: "* Required",
                          min: {
                            value: 1,
                            message: "Study duration must be greater than or equal to 1 hour",
                          },
                          max: {
                            value: 720,
                            message: "Study duration must be less than or equal to 720 hours",
                          },
                        }}
                        data-cy="recorded-duration-input"
                      />
                    </Box>
                  )}

                  {/* PHYSICIAN COMMENTS */}
                  {displayCommentsInput && (
                    <Box sx={{mt: 2}}>
                      <FormStringInput
                        control={control}
                        name="physicianComment"
                        label="Reading Physician Comments"
                        defaultValue=""
                        required={false}
                        size="small"
                        otherProps={{
                          variant: "outlined",
                          multiline: true,
                          minRows: 12,
                          maxRows: 18,
                          onBlur: handleUpdateCoverPage,
                        }}
                        rules={{
                          maxLength: {
                            value: 5000,
                            message: "Reading Physician comments exceed max character limit",
                          },
                        }}
                        data-cy="physician-comment-input"
                      />
                    </Box>
                  )}
                </Box>

                {/* REPORT ACTIONS */}
                <Box>
                  {features.finalizeStudy && ["Submit for Signature", "Publish"].includes(action?.text) && (
                    <Box
                      sx={{display: "inline-flex", alignItems: "center", justifyContent: "space-between"}}
                      width="100%"
                      mb={2}
                    >
                      <Box display="inline-flex">
                        <Typography variant="body2" align="right">
                          Mark study as finalized
                        </Typography>
                        <InfoTooltip placement="top">
                          Finalize a study if there is no further analysis to be performed
                        </InfoTooltip>
                      </Box>
                      <Tooltip
                        title={study.finalizedAt ? "Study has already been finalized" : null}
                        placement="top"
                      >
                        <span>
                          <SwitchInput
                            control={control}
                            name="markAsFinalized"
                            data-cy="mark-as-finalized-toggle"
                            defaultValue={
                              !!study.finalizedAt || ["Uploaded", "Summary"].includes(pdfReport.reportType)
                            }
                            color="success"
                            disabled={!!study.finalizedAt}
                            otherProps={{edge: "end"}}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  )}

                  {action?.text === "Agree and Sign" && (
                    <Typography variant="body2" align="right" sx={{mb: 2}}>
                      By clicking &quot;Agree and Sign&quot;, I consent to signing this document
                      electronically.
                    </Typography>
                  )}
                  {!!action?.text && (
                    <Tooltip
                      title={
                        doesNotMeetExtendedHolterRequirements
                          ? `Report does not meet criteria to ${action.text} without downgrade authorization`
                          : ""
                      }
                    >
                      <span>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          startIcon={action.icon}
                          onClick={handleSubmit(onSubmit)}
                          data-cy="action-button"
                          disabled={doesNotMeetExtendedHolterRequirements}
                        >
                          {action.text}
                        </Button>
                      </span>
                    </Tooltip>
                  )}

                  {displayRejectButton && (
                    <Button
                      color="tertiary"
                      variant="outlined"
                      fullWidth
                      startIcon={<DoDisturbOn />}
                      sx={{mt: 2}}
                      onClick={() => setOpenRejectConfirmation(true)}
                      data-cy="reject-button"
                    >
                      Reject
                    </Button>
                  )}
                  {displayUnrejectButton && (
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      startIcon={<DoDisturbOff />}
                      onClick={handleClickUnreject}
                      data-cy="unreject-button"
                    >
                      Unreject
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>

      <RejectConfirmation
        open={openRejectConfirmation}
        handleClose={() => setOpenRejectConfirmation(false)}
        onSubmit={rejectReport}
        report={pdfReport}
        submitting={submitting}
      />
    </>
  );
}

PdfReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
};

export default PdfReportDialog;
