/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useLocalStorage} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {useReports, useReportsDispatch} from "../../contexts/ReportsContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import useJwt from "../hooks/useJwt.jsx";
import useStudyTypeNames from "../hooks/useStudyTypeNames.jsx";
import ReportsTable from "./ReportsTable.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Search options
//---------------------------------------------------------------------------
const constantSearchFields = {
  study: "studyId",
  facility: "facilityName",
  number: "reportNumber",
  findings: "comment",
  type: (object) =>
    object.reportType === "Single Episode"
      ? `${object.reportType} - ${object.eventClassification}`
      : object.reportType,
  is: {
    mdn: (object) => object.meetsMdnCriteria,
    generated: (object) => object.state === "generated",
    submitted: (object) => object.state === "submitted",
    published: (object) => object.state === "published",
    signed: (object) => object.state === "signed",
    printed: (object) => object.state === "printed",
    rejectedByTech: (object) => object.state === "rejectedByTech",
    rejectedByPhysician: (object) => object.state === "rejectedByPhysician",
  },
  timestamp: "timestamp",
  patient: [(object) => object.studyDetails?.patientName, (object) => object.studyDetails?.patientId],
  gender: (object) => object.studyDetails?.patientGender,
  "physician-name": (object) => object.studyDetails?.physicianName,
  "physician-phone": (object) => object.studyDetails?.physicianPhone,
  "physician-facility": (object) => object.studyDetails?.physicianFacility,
  "physician-email": (object) => object.studyDetails?.physicianEmail,
  "physician-address": (object) => object.studyDetails?.physicianAddress,
  physician: [
    (object) => object.studyDetails?.physicianName,
    (object) => object.studyDetails?.physicianPhone,
    (object) => object.studyDetails?.physicianFacility,
    (object) => object.studyDetails?.physicianEmail,
    (object) => object.studyDetails?.physicianAddress,
  ],
};

function ReportsPage({
  // Props
  searchText = "",
}) {
  const displayableStudyTypes = useStudyTypeNames();
  const {username, isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const reports = useReports();
  const dispatch = useReportsDispatch();

  const getReports = React.useCallback(async () => {
    try {
      const {data: reportsResponse} = await axios({
        method: "get",
        url: "/generatedReports",
      });

      dispatch({type: "init", elements: reportsResponse});
    } catch (err) {
      // Only display 500 error popup if we failed to load reports for the page
      if (err.response?.status !== 500 || reports.length === 0) {
        setError(err?.message);
      } else {
        setError(null);
      }
    }

    setLoading(false);
  }, [dispatch, reports.length]);

  useInterval(getReports, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Search support
  //---------------------------------------------------------------------------
  const defaultFilter = React.useMemo(
    () => ({
      ...(!isInAnyRole(["physician", "clinicalStaff"]) && {generated: true}),
      submitted: true,
      published: true,
      signed: !isInAnyRole(["physician"]),
      printed: !isInAnyRole(["physician"]),
      ...(!isInAnyRole(["physician", "clinicalStaff"]) && {rejectedByTech: false}),
      rejectedByPhysician: !isInAnyRole(["physician", "clinicalStaff"]),
    }),
    [isInAnyRole]
  );
  const [statusFilter, setStatusFilter] = useLocalStorage(`${username}.reports.filter`, defaultFilter);

  //---------------------------------------------------------------------------
  // Search support
  //---------------------------------------------------------------------------
  const searchFields = React.useMemo(() => {
    return {
      ...constantSearchFields,
      "study-type": [(object) => object.studyType, (object) => displayableStudyTypes[object.studyType]],
    };
  }, [displayableStudyTypes]);
  const search = React.useMemo(() => {
    const statusToFilterOut = Object.entries(statusFilter)
      .filter(([status, selected]) => !selected)
      .map(([status, selected]) => `-is:${status}`);

    return `${searchText} ${statusToFilterOut.join(" ")}`;
  }, [statusFilter, searchText]);
  const filteredReports = useFilter(reports, search, searchFields);

  const mdnReports = React.useMemo(
    () => filteredReports.filter((report) => report.meetsMdnCriteria),
    [filteredReports]
  );
  const nonMdnReports = React.useMemo(
    () => filteredReports.filter((report) => !report.meetsMdnCriteria),
    [filteredReports]
  );

  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}
      {!loading && (
        <>
          {/* MDN CRITERIA REPORTS */}
          <ReportsTable
            search={search}
            pageSize={10}
            reports={mdnReports}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            displayNoResults={false}
          />

          {/* NON MDN CRITERIA REPORTS */}
          <ReportsTable
            search={search}
            pageSize={20}
            reports={nonMdnReports}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
          />
        </>
      )}
    </>
  );
}

ReportsPage.propTypes = {
  searchText: PropTypes.string,
};

export default ReportsPage;
