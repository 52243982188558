import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import ReportUploadWrapper from "./ReportUploadWrapper.jsx";

export default angular.module("app.components.reportUpload", []).name;

// matches <br-report-upload>
angularizeDirective(ReportUploadWrapper, "brReportUpload", angular.module("app.components.reportUpload"), {
  study: "=",
});
