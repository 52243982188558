/* eslint-env browser */
import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import MailOutlineIcon from "@mui/icons-material/MailOutline";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip from "@mui/material/Tooltip";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import AddProviders from "../../shared/react/AddProviders.jsx";

function DailyTrendEventsButton({
  // Props
  item,
}) {
  const [buttonText, setButtonText] = React.useState("- Completed Events");
  const [loading, setLoading] = React.useState(true);
  const startReportPeriod = React.useMemo(
    () => DateTime.fromISO(item.timestamp).toUTC().minus({days: 1, milliseconds: 1}),
    [item.timestamp]
  );
  const endReportPeriod = React.useMemo(() => DateTime.fromISO(item.timestamp).toUTC(), [item.timestamp]);

  const getDailyTrendCount = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios({
        method: "get",
        url: "/inboxItems",
        params: {
          "$enrollment.study.id$": item.studyId,
          completed: true,
          category: "ECG Event",
          timestamp: {
            $gt: startReportPeriod.toFormat("yyyy-MM-dd hh:mm:ss.SSSZZ"),
            $lt: endReportPeriod.toFormat("yyyy-MM-dd hh:mm:ss.SSSZZ"),
          },
          limit: 0,
        },
      });

      setLoading(false);
      setButtonText(
        `${response.headers.count} Completed Event${Number(response.headers.count) === 1 ? "" : "s"}`
      );
    } catch (err) {
      setLoading(false);
    }
  }, [endReportPeriod, item.studyId, startReportPeriod]);

  React.useEffect(() => {
    getDailyTrendCount();
  }, [getDailyTrendCount]);

  const onClick = React.useCallback(() => {
    const adjustedStart = startReportPeriod
      .setZone(item.study.timeZone)
      .toFormat("yyyy-MM-dd hh:mm:ss.SSS ZZZZ");
    const adjustedEnd = endReportPeriod.setZone(item.study.timeZone).toFormat("yyyy-MM-dd hh:mm:ss.SSS ZZZZ");
    const timeExpired = DateTime.now().plus({seconds: 30}).toMillis();

    // Set search that will fill when a new page is opened
    window.localStorage.setItem(
      "search",
      JSON.stringify({
        text: `study:${item.studyId} is:event after:"${adjustedStart}" before:"${adjustedEnd}"`,
        timeExpired,
      })
    );

    // open completed page in a new tab
    window.open("/completed", "_blank");
  }, [endReportPeriod, startReportPeriod, item.study.timeZone, item.studyId]);

  return (
    <AddProviders>
      <Tooltip title="View all completed ECG events for this Daily Trend" placement="top">
        <span>
          <LoadingButton
            onClick={onClick}
            color="secondary"
            loading={loading}
            variant="outlined"
            data-cy={`${item.id}-completed-daily-events-button`}
            sx={{borderRadius: 0.5, margin: 2}}
          >
            <MailOutlineIcon />
            &nbsp;
            {buttonText}
          </LoadingButton>
        </span>
      </Tooltip>
    </AddProviders>
  );
}

DailyTrendEventsButton.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DailyTrendEventsButton;
