/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ColumnHeader from "../../shared/react/ColumnHeader.jsx";
import StatusFilter from "../../shared/react/StatusFilter.jsx";
import useJwt from "../hooks/useJwt.jsx";
import ReportStatusChip from "./ReportStatusChip.jsx";

function ReportsHeader({
  // Props
  sort,
  setSort,
  statusFilter,
  setStatusFilter,
}) {
  //---------------------------------------------------------------------------
  // Handle filtering callbacks
  //---------------------------------------------------------------------------
  const {isInAnyRole} = useJwt();
  const statuses = React.useMemo(() => {
    const allowedStatuses = [
      ...(!isInAnyRole(["physician", "clinicalStaff"]) ? ["generated"] : []),
      "submitted",
      "published",
      "signed",
      "printed",
      ...(!isInAnyRole(["physician", "clinicalStaff"]) ? ["rejectedByTech"] : []),
      "rejectedByPhysician",
    ];

    return allowedStatuses.map((status) => ({
      id: status,
      label: <ReportStatusChip status={status} />,
      handleClick: () => setStatusFilter((prev) => ({...prev, [status]: !prev[status]})),
    }));
  }, [isInAnyRole, setStatusFilter]);

  //---------------------------------------------------------------------------
  // Render the column headers plus the filter select if needed
  //---------------------------------------------------------------------------
  return (
    <Grid container alignItems="center" columns={40} spacing={2} pb={1}>
      <Grid item xs={15} sm={8} md={5} lg={4} display="inline-flex" alignItems="center">
        <ColumnHeader
          id="state"
          display="Status"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />

        <StatusFilter statuses={statuses} statusFilter={statusFilter} />
      </Grid>
      <Grid item xs={20} sm={12} md={7} lg={5} display="inline-flex" alignItems="center">
        <ColumnHeader
          id="patientName"
          display="Patient"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid item md={5} lg={4} display={{xs: "none", md: "inline-flex"}} alignItems="center">
        <ColumnHeader
          id="studyId"
          display="Study"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid item sm={2} display={{xs: "none", sm: "inline-flex"}} alignItems="center">
        <ColumnHeader
          id="reportNumber"
          display="Number"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
          justify="left"
        />
      </Grid>
      <Grid item sm={15} md={11} lg={9} display={{xs: "none", sm: "inline-flex"}} alignItems="center">
        <ColumnHeader
          id="reportType"
          display="Type"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid item lg={9} display={{xs: "none", lg: "inline-flex"}} alignItems="center">
        <ColumnHeader
          id="comment"
          display="Findings"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid item md={7} lg={5} display={{xs: "none", md: "inline-flex"}} alignItems="center">
        <ColumnHeader
          id="timestamp"
          display="Timestamp"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
    </Grid>
  );
}
ReportsHeader.propTypes = {
  sort: PropTypes.object.isRequired,
  setSort: PropTypes.func.isRequired,
  statusFilter: PropTypes.object,
  setStatusFilter: PropTypes.func,
};
export default React.memo(ReportsHeader);
