/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import OpenInNew from "@mui/icons-material/OpenInNew";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import CardContentNoPadding from "../../shared/react/CardContentNoPadding.jsx";
import DateAndTime from "../DateAndTime/DateAndTime.jsx";
import useDoesNotMeetExtendedHolterRequirements from "../hooks/useDoesNotMeetExtendedHolterRequirements.jsx";
import PdfReportDialog from "../PdfReport/PdfReportDialog.jsx";
import ReportStatusChip from "./ReportStatusChip.jsx";

function ReportRow({
  // Props
  report,
}) {
  //---------------------------------------------------------------------------
  // Handle opening report rows
  //---------------------------------------------------------------------------
  const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
  const isPdfReport = React.useMemo(
    () => report.reportType === "Uploaded" || ["published", "signed", "printed"].includes(report.state),
    [report.reportType, report.state]
  );
  const handleClickReport = React.useCallback(() => {
    const reportTypeRouteMap = {
      "Single Episode": "single-episode",
      "Daily Trend": "daily-trend",
      Summary: "summary",
    };

    // If the report is a PDF, open the React PDF viewer
    if (isPdfReport) {
      setOpenPdfViewer(true);
    }
    // Otherwise, open the raw Angular report in a new tab
    else {
      const url = `/reports/${reportTypeRouteMap[report.reportType]}/${report.id}`;
      window.open(url, "_blank");
    }
  }, [report.reportType, report.id, isPdfReport]);

  //---------------------------------------------------------------------------
  // Get displayed values for the report row
  //---------------------------------------------------------------------------
  const type = React.useMemo(() => {
    if (report.reportType === "Single Episode" && report.eventClassification) {
      return `${report.reportType} - ${report.eventClassification}`;
    }
    return report.reportType;
  }, [report.reportType, report.eventClassification]);

  //---------------------------------------------------------------------------
  // Determine report warning messages
  //---------------------------------------------------------------------------
  const doesNotMeetExtendedHolterRequirements = useDoesNotMeetExtendedHolterRequirements(
    report.studyType,
    report.study?.downgradeAuthorized,
    report.study?.configuredDuration,
    report.study?.recordedDuration
  );

  return (
    <>
      <Card square sx={{width: "100%"}}>
        <CardContentNoPadding spacing={0} data-cy={`report-${report.id}`}>
          <CardActionArea onClick={handleClickReport}>
            <Grid container alignItems="center" spacing={2} columns={40}>
              <Grid item xs={15} sm={8} md={5} lg={4}>
                <Typography variant="cardRow" pl={2}>
                  <ReportStatusChip status={report.state} data-cy={`status-${report.id}`} />
                </Typography>
              </Grid>

              <Grid item xs={20} sm={12} md={7} lg={5}>
                <Typography variant="cardRow">{report.studyDetails.patientName}</Typography>
              </Grid>

              <Grid
                item
                md={5}
                lg={4}
                display={{xs: "none", md: "inline-flex"}}
                alignItems="center"
                data-cy={`study-id-${report.id}`}
              >
                <Typography variant="cardRow">{report.studyId}</Typography>

                {doesNotMeetExtendedHolterRequirements && (
                  <Tooltip title="Study duration does not meet criteria to publish or submit reports without downgrade authorization">
                    <WarningAmberIcon
                      color="warning"
                      sx={{paddingLeft: "10px"}}
                      data-cy={`report-warning-icon-${report.id}`}
                    />
                  </Tooltip>
                )}
              </Grid>

              <Grid
                item
                sm={2}
                display={{xs: "none", sm: "inline-flex"}}
                justifyContent="end"
                data-cy={`number-${report.id}`}
              >
                <Typography variant="cardRow">{report.reportNumber}</Typography>
              </Grid>

              <Grid
                item
                sm={15}
                md={11}
                lg={9}
                display={{xs: "none", sm: "inline-flex"}}
                data-cy={`type-${report.id}`}
              >
                <Typography variant="cardRow">
                  {report.meetsMdnCriteria && (
                    <Chip
                      label="MDN"
                      size="small"
                      color="error"
                      sx={{mr: 1}}
                      data-cy={`mdn-criteria-${report.id}`}
                    />
                  )}
                  {type}
                </Typography>
              </Grid>

              <Grid item lg={9} display={{xs: "none", lg: "inline-flex"}}>
                <Typography variant="cardRow">{report.comment}</Typography>
              </Grid>

              <Grid
                item
                md={7}
                lg={5}
                display={{xs: "none", md: "inline-flex"}}
                data-cy={`timestamp-${report.id}`}
              >
                <Typography variant="cardRow">
                  <DateAndTime datetime={report.timestamp} />
                </Typography>
              </Grid>

              <Grid item xs>
                {!isPdfReport && (
                  <Tooltip title="Report will be opened in a new tab">
                    <OpenInNew fontSize="small" color="tertiary" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </CardActionArea>
        </CardContentNoPadding>
      </Card>

      <PdfReportDialog open={openPdfViewer} setOpen={setOpenPdfViewer} report={report} />
    </>
  );
}

ReportRow.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ReportRow;
